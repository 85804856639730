<template>
  <Container>
    <CategoryTitle
      title="이벤트 정보"
      className="mb-8"
    ></CategoryTitle>
    <div class="grid grid-cols-4 gap-10  mb-5 event-form">
      <div>
        <FormLabel
          isRequired
          name="이벤트코드 (Code)"
          description="영어 소문자, 숫자 조합으로 20자 이내"
          class="text-sm"
        ></FormLabel>
        <Input
          className="w-full text-sm"
          :value="eventData.code"
          placeholder="예 : blacklot20200401"
          :onChange="(e) => handleChangeEventData('code', e.target.value)"
        ></Input>
      </div>
      <div class="col-span-2">
        <FormLabel
          isRequired
          name="이벤트 타이틀 (Title)"
          description="이벤트 제목 입력 (50자 이내)"
          class="text-sm"
        ></FormLabel>
        <Input
          className="w-full text-sm"
          :value="eventData.title"
          placeholder="예 : LIVE AUCTION EVENT"
          :onChange="(e) => handleChangeEventData('title', e.target.value)"
        ></Input>
      </div>
      <div>
        <FormLabel
          isRequired
          name="이벤트 기간"
          description="이벤트 시작 - 종료 시간 설정"
          class="text-sm"
        ></FormLabel>
        <div class="flex items-center border border-black overflow-hidden">
          <Input
            :min="getNowDateString()"
            type="datetime-local"
            className="border-none text-xs pl-2 pr-0"
            style="width: 165px; font-size: 0.75rem; line-height: 1rem;"
            :value="
              eventData.startDate
              ? getDateTimeLocalValue(eventData.startDate)
              : ''
            "
            :onChange="
              (e) => {
                handleChangeDate('startDate', e.target.value);
              }
            "
          />
          <span>-</span>
          <Input
            :min="getNowDateString()"
            type="datetime-local"
            className="border-none dateTime text-xs pr-2 pl-0"
            style="width: 165px; font-size: 0.75rem; line-height: 1rem;"
            :value="eventData.endDate ? getDateTimeLocalValue(eventData.endDate) : ''"
            :onChange="
              (e) => {
                handleChangeDate('endDate', e.target.value);
              }
            "
          />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-10 mb-5">
      <div class="col-span-1">
        <FormLabel
            name="이벤트 테마 버전"
            description="이벤트에 사용할 테마 버전 (예 : v3, original)"
            class="text-sm"
        ></FormLabel>
        <Input
            className="w-full text-sm"
            :value="eventData.version"
            placeholder="예) v3, original"
            :onChange="(e) => handleChangeEventData('version', e.target.value)"
        ></Input>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-10 mb-5">
      <div class="col-span-3">
        <FormLabel
          name="이벤트 설명 (Description)"
          description="이벤트에 대한 소개글 입력 (200자 이내)"
          class="text-sm"
        ></FormLabel>
        <Input
          className="w-full text-sm"
          :value="eventData.description"
          placeholder="예 : 2022년을 여는 첫 이벤트. 100작품 한정"
          :onChange="(e) => handleChangeEventData('description', e.target.value)"
        ></Input>
      </div>
    </div>
    <FormLabel
      name="이벤트 카테고리"
      description="최대 8개까지 입력 순서대로 노출"
      class="text-sm"
    ></FormLabel>
    <div class="grid grid-cols-8 gap-5 mb-5">
      <div v-for="(ct, idx) in 8" :key="idx">
        <Input
          className="w-full text-sm"
          :value="eventData.category[idx] || ''"
          :placeholder="`카테고리 이름 ${idx+1}`"
          :onChange="(e) => handleChangeEventDataCategory(idx, e.target.value)"
        ></Input>
      </div>
    </div>

    <!-- (구) 이벤트 배너 1 -->
    <div class="grid grid-cols-4 gap-10 mb-12">
      <div class="col-span-3">
        <FormLabel
            name="이벤트 배너1"
            description="1920 * 480 사이즈"
            class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
              class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              h-60
            "
              for="eventBanner1"
          >
            <input
                type="file"
                name="productImageList"
                accept=".pdf,.jpg,.jpeg,.png"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                id="eventBanner1"
                ref="eventBanner1"
                @change="(e) => handleChangeImageInput('banner1', e)"
            />
            <span
                class="banner-image"
                :style="{
                backgroundImage: `url(${eventData.bannerUrl1 || '/images/default-img-blue.png'})`,
              }"
            ></span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                  :width="48"
                  :height="48"
                  color="#ffffff"
                  viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
      <div>
        <FormLabel
            name="이벤트 배너2"
            description="300 * 300 사이즈"
            class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
              class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              w-60
              h-60
            "
              for="eventBanner2"
          >
            <input
                type="file"
                name="productImageList"
                accept=".pdf,.jpg,.jpeg,.png"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                id="eventBanner2"
                ref="eventBanner2"
                @change="(e) => handleChangeImageInput('banner2', e)"
            />
            <span
                class="banner-image"
                :style="{
                backgroundImage: `url(${eventData.bannerUrl2 || '/images/default-img-blue.png'})`,
              }"
            >
            </span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                  :width="48"
                  :height="48"
                  color="#ffffff"
                  viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
    </div>
    <!-- 이벤트 배너 1 -->
    <div class="grid grid-cols-4 gap-10 mb-12">
      <div class="col-span-3">
        <FormLabel
          name="이벤트 웹 배너1"
          description="1920 * 480 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              h-60
            "
            for="eventWebBanner1"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventWebBanner1"
              ref="eventWebBanner1"
              @change="(e) => handleChangeImageInput('webBanner1', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.webBannerUrl1 || '/images/default-img-blue.png'})`,
              }"
            ></span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
      <div>
        <FormLabel
          name="이벤트 모바일 배너1"
          description="300 * 300 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              w-60
              h-60
            "
            for="eventMobileBanner1"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventMobileBanner1"
              ref="eventMobileBanner1"
              @change="(e) => handleChangeImageInput('mobileBanner1', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.mobileBannerUrl1 || '/images/default-img-blue.png'})`,
              }"
            >
            </span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
    </div>
    <!-- 이벤트 배너 2 -->
    <div class="grid grid-cols-4 gap-10 mb-12">
      <div class="col-span-3">
        <FormLabel
          name="이벤트 웹 배너2"
          description="1920 * 480 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              h-60
            "
            for="eventWebBanner2"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventWebBanner2"
              ref="eventWebBanner2"
              @change="(e) => handleChangeImageInput('webBanner2', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.webBannerUrl2 || '/images/default-img-blue.png'})`,
              }"
            ></span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
      <div>
        <FormLabel
          name="이벤트 모바일 배너2"
          description="300 * 300 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              w-60
              h-60
            "
            for="eventMobileBanner2"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventMobileBanner2"
              ref="eventMobileBanner2"
              @change="(e) => handleChangeImageInput('mobileBanner2', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.mobileBannerUrl2 || '/images/default-img-blue.png'})`,
              }"
            >
            </span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
    </div>

    <!-- 이벤트 배너 3 -->
    <div class="grid grid-cols-4 gap-10 mb-12">
      <div class="col-span-3">
        <FormLabel
          name="이벤트 웹 배너3"
          description="1920 * 480 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              h-60
            "
            for="eventWebBanner3"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventWebBanner3"
              ref="eventWebBanner3"
              @change="(e) => handleChangeImageInput('webBanner3', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.webBannerUrl3 || '/images/default-img-blue.png'})`,
              }"
            ></span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
      <div>
        <FormLabel
          name="이벤트 모바일 배너3"
          description="300 * 300 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              w-60
              h-60
            "
            for="eventMobileBanner3"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventMobileBanner3"
              ref="eventMobileBanner3"
              @change="(e) => handleChangeImageInput('mobileBanner3', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.mobileBannerUrl3 || '/images/default-img-blue.png'})`,
              }"
            >
            </span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
    </div>

    <!-- 이벤트 배너 4 -->
    <div class="grid grid-cols-4 gap-10 mb-12">
      <div class="col-span-3">
        <FormLabel
          name="이벤트 웹 배너4"
          description="1920 * 480 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              h-60
            "
            for="eventWebBanner4"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventWebBanner4"
              ref="eventWebBanner4"
              @change="(e) => handleChangeImageInput('webBanner4', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.webBannerUrl4 || '/images/default-img-blue.png'})`,
              }"
            ></span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
      <div>
        <FormLabel
          name="이벤트 모바일 배너4"
          description="300 * 300 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              w-60
              h-60
            "
            for="eventMobileBanner4"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventMobileBanner4"
              ref="eventMobileBanner4"
              @change="(e) => handleChangeImageInput('mobileBanner4', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.mobileBannerUrl4 || '/images/default-img-blue.png'})`,
              }"
            >
            </span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
    </div>

    <!-- 이벤트 배너 5 -->
    <div class="grid grid-cols-4 gap-10 mb-12">
      <div class="col-span-3">
        <FormLabel
          name="이벤트 웹 배너5"
          description="1920 * 480 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              h-60
            "
            for="eventWebBanner5"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventWebBanner5"
              ref="eventWebBanner5"
              @change="(e) => handleChangeImageInput('webBanner5', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.webBannerUrl5 || '/images/default-img-blue.png'})`,
              }"
            ></span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
      <div>
        <FormLabel
          name="이벤트 모바일 배너5"
          description="300 * 300 사이즈"
          class="text-sm"
        ></FormLabel>
        <div class="w-full">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
              w-60
              h-60
            "
            for="eventMobileBanner5"
          >
            <input
              type="file"
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              id="eventMobileBanner5"
              ref="eventMobileBanner5"
              @change="(e) => handleChangeImageInput('mobileBanner5', e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${eventData.mobileBannerUrl5 || '/images/default-img-blue.png'})`,
              }"
            >
            </span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
        </div>
      </div>
    </div>
    <!-- 이벤트 배너 끝 -->




    <div class="flex items-center justify-center">
      <button
        class="w-64 h-12 button-shadow inline-flex text-red-500 items-center justify-center border border-red-500 text-sm bg-white"
        @click="handleDeleteEvent"
        v-if="query.eventId"
      >
        <IconBase>
          <RemoveIcon></RemoveIcon>
        </IconBase>
        <span class="ml-2">삭제하기</span>
      </button>
      <div
        v-if="query.eventId"
        class="table-bottom-line mx-3"
      ></div>
      <button
        class="w-64 h-12 button-shadow inline-flex  items-center justify-center border border-black text-sm"
        @click="handleRegister"
      >
        <IconBase>
          <EditIcon v-if="query.eventId"></EditIcon>
          <CheckCircleIcon v-else></CheckCircleIcon>
        </IconBase>
        <span class="ml-2">
          <template v-if="query.eventId">수정하기</template>
          <template v-else>등록하기</template>
        </span>
      </button>
    </div>
    <CategoryTitle
      title="작품목록"
      className="mb-8 mt-8"
    ></CategoryTitle>
    <div class="flex items-center">
      <strong class="text-label font-normal text-sm  flex-none">Category</strong>
      <span class="inline-block bg-gray-400 h-4 w-px mx-3"></span>

      <button
        class="text-label-label rounded-full h-8 px-4 text-sm bg-gray-300 flex-none"
        :class="{ 'active': !filterParams.eventCategory }"
        @click="handleChangeCategoryOption('')"
      >전체</button>

      <span class="inline-block bg-gray-400 h-4 w-px mx-3"></span>
      <div v-if="query.eventId" class="flex justify-start items-center flex-wrap">
        <button
          v-for="(cate, idx) in productData.event?.category || []"
          :key="idx"
          class="mr-2 text-label-label rounded-full h-8 px-4 text-sm bg-gray-300"
          :class="{ 'active' : filterParams.eventCategory && filterParams.eventCategory === cate }"
          @click="handleChangeCategoryOption(cate)"
        >{{ cate }}</button>
      </div>
    </div>
    <div class="flex items-center mt-4">
      <strong class="text-label font-normal text-sm  flex-none">Seller</strong>
      <span class="inline-block bg-gray-400 h-4 w-px mx-3"></span>
      <button
        class="text-label-label rounded-full h-8 px-4 text-sm bg-gray-300 flex-none"
        :class="{ 'active': !filterParams.partnerId }"
        @click="handleChangePartnerOption(undefined)"
      >전체</button>

      <span class="inline-block bg-gray-400 h-4 w-px mx-3"></span>
      <div v-if="query.eventId" class="flex justify-start items-center flex-wrap">
        <button
          v-for="(partner, idx) in productData.partnerList" :key="idx"
          class="mr-2 text-label-label rounded-full h-8 px-4 text-sm bg-gray-300 mt-2"
          :class="{ 'active' : filterParams.partnerId && filterParams.partnerId === partner.partnerId }"
          @click="handleChangePartnerOption(partner.partnerId)"
        >{{ partner.companyName }}</button>
      </div>
    </div>
    <div v-if="query.eventId">
      <p class="text-sm mt-6">전체 {{ productData.total }}건</p>
      <table class="ListTable table-fixed w-full text-center text-xs mt-3">
        <thead class="bg-gray-200">
        <tr class="h-10">
          <th class="w-10 border-t border-black border-b">
            <div class="flex items-center pl-2 justify-center">
              <input
                type="checkbox"
                class="w-4 h-4 checkbox block mr-2 rounded border-gray-300 text-blue-sky focus:ring focus:ring-offset-0 focus:ring-blue-sky focus:ring-opacity-10"
                @click="handleAllCheck"
              />
              No.
            </div>
          </th>
          <th class="w-10 border-t border-black border-b">LOT Number</th>
          <th class="w-10 border-t border-black border-b">Category</th>
          <th class="w-10 border-t border-black border-b">Seller</th>
          <th class="w-16 border-t border-black border-b">이미지</th>
          <th class="w-18 border-t border-black border-b">작가명</th>
          <th class="w-20 border-t border-black border-b">작품명</th>
          <th class="w-10 border-t border-black border-b">판매옵션</th>
          <th class="w-14 border-t border-black border-b">판매기간</th>
          <th class="w-10 border-t border-black border-b">시작가</th>
          <th class="w-10 border-t border-black border-b">Buy Now 가격</th>
        </tr>
        </thead>
        <tbody>
        <tr
          class="border-b border-grey-300 text-center cursor-pointer"
          v-for="(item, index) in productData.productList"
          :key="index"
        >
          <!-- No check -->
          <td>
            <div class="flex items-center justify-center">
              <input
                type="checkbox"
                class="w-4 h-4 checkbox block mr-2 rounded border-gray-300 text-blue-sky focus:ring focus:ring-offset-0 focus:ring-blue-sky focus:ring-opacity-10"
                :checked="item.checked"
                @click="(e) => handleCheck(e, index)"
              />
              {{ item.productId || '-' }}
            </div>
          </td>
          <td @click="handleClickRow(item)">
            {{ item.lot }}
          </td>
          <td class="px-2" @click="handleClickRow(item)">
            <p class="break-all">
              {{ item.eventCategory || '-' }}
            </p>
          </td>
          <td class="px-2" @click="handleClickRow(item)">
            <p class="truncate">{{ item.partner?.companyName || '-' }}</p>
          </td>
          <!-- 이미지 -->
          <td @click="handleClickRow(item)">
            <div
              class="ProductImageItem w-full h-14 bg-gray-200 mr-1 bg-cover bg-center relative flex items-center justify-center"
              :style="{ backgroundImage: `url(${ item.productImageList[0]?.url })` }"
            ></div>
          </td>
          <!-- 작가명 -->
          <td class="px-2" @click="handleClickRow(item)">
            <p class="truncate">{{ item.artistName || '-' }}</p>
          </td>
          <!-- 작품명-->
          <td @click="handleClickRow(item)">
            <p class="truncate">{{ item.title || '-' }}</p>
          </td>
          <!-- 판매옵션 -->
          <td @click="handleClickRow(item)">
            {{ item.buynowOnly ? 'Buy Now Only' : 'Bid Now' }}
          </td>
          <!-- 판매기간 -->
          <td @click="handleClickRow(item)">
           <span v-if="item.startDate">
              {{ format(new Date(item.startDate), 'yyyy/MM/dd HH:mm') }}
            </span>
            <span v-else>-</span>
            <span v-if="item.endDate">
            ~ {{ format(new Date(item.endDate), 'yyyy/MM/dd HH:mm') }}
            </span>
            <span v-else>-</span>
          </td>
          <!-- 시작가 -->
          <td @click="handleClickRow(item)">
            {{ convertNumWithComma(item.startPrice, '원') }}
          </td>
          <!-- buynow 가격 -->
          <td @click="handleClickRow(item)">
            {{ convertNumWithComma(item.buynowPrice, '원') }}
          </td>
        </tr>
        </tbody>
      </table>
      <div class="mt-4 flex items-center event-register-bottom">
        <button
          class="w-52 h-9 button-shadow inline-flex text-red-500 items-center justify-center border border-red-500 text-sm bg-white"
          @click="handleExceptEvent"
        >
          <IconBase>
            <RemoveIcon></RemoveIcon>
          </IconBase>
          <span class="ml-1">이벤트에서 제외</span>
        </button>
        <div class="table-bottom-line mx-3"></div>
        <select
          v-model="categoryBox"
          class="h-9 w-52 border border-black px-3 text-black mr-2 text-sm admin-select appearance-none FormSelect"
        >
          <option value="">Category</option>
          <option
            v-for="(cate, idx) in productData.event?.category || []"
            :key="idx"
            :value="cate"
          >{{ cate }}</option>
        </select>
        <button
          class="w-52 h-9 button-shadow inline-flex text-white items-center justify-center bg-black text-sm"
          @click="handleChangeCategory"
        >
          <IconBase>
            <CheckCircleIcon></CheckCircleIcon>
          </IconBase>
          <span class="ml-2">변경하기</span>
        </button>
        <Pagination
          :initialPage="filterParams.page"
          :pageSize="parseInt(filterParams.pageSize)"
          :onChange="handleChangePage"
          :onChangePageSize="handleChangePageSize"
          :total="productData.total"
        ></Pagination>
      </div>
    </div>
  </Container>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import Container from '@/components/Container.vue'
import CategoryTitle from '@/views/product/ProductRegister/CategoryTitle.vue'
import FormLabel from '@/components/FormLabel.vue'
import Input from '@/components/Input.vue'
import format from 'date-fns/format'
import datePattern from '@/constants/datePattern'
import partnerAPI from '@/service/partnerAPI'
import IconBase from '@/components/icons/IconBase.vue'
import PictureIcon from '@/views/layouts/AppLayout/icons/PictureIcon.vue'
import getServerErrorMessage from '@/utils/getServerErrorMessage'
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue'
import RemoveIcon from '@/components/icons/RemoveIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import router from '@/router/index'
import convertNumWithComma from '@/utils/convertNumWithComma'
import Pagination from '@/components/Pagination/index.vue'
import {EventCategoryDTO} from "@/openapi";

export default {
  name: 'eventRegister',
  components: {
    Container,
    CategoryTitle,
    FormLabel,
    Input,
    IconBase,
    PictureIcon,
    CheckCircleIcon,
    RemoveIcon,
    EditIcon,
    Pagination
  },
  setup () {
    const eventData = ref({
      bannerUrl1: '',
      bannerUrl2: '',
      webBannerUrl1: '',
      webBannerUrl2: '',
      webBannerUrl3: '',
      webBannerUrl4: '',
      webBannerUrl5: '',
      mobileBannerUrl1: '',
      mobileBannerUrl2: '',
      mobileBannerUrl3: '',
      mobileBannerUrl4: '',
      mobileBannerUrl5: '',
      code: '',
      version: '',
      category: ['', '', '', '', '', '', '', ''],
      categoryV2: [{}, {}, {}, {}, {}, {}, {}, {}],
      title: '',
      startDate: '',
      endDate: ''
    })
    const route = useRoute()
    const query = route.query
    console.log(query)

    const handleChangeImageInput = async (type, e) => {
      if (e.target.files) {
        await uploadImageFiles(type, e.target.files)
      }
    }

    const uploadImageFiles = async (type, files) => {
      try {
        const { data } = await partnerAPI.adminFile.adminFileUpload({
          folderPath: 'event', file: files[0]
        })

        if (type === 'banner1') {
          eventData.value.bannerUrl1 = data;
        } else if (type === 'banner2') {
          eventData.value.bannerUrl2 = data;
        } else if (type === 'webBanner1') {
          eventData.value.webBannerUrl1 = data
        } else if (type === 'webBanner2') {
          eventData.value.webBannerUrl2 = data;
        } else if (type === 'webBanner3') {
          eventData.value.webBannerUrl3 = data;
        } else if (type === 'webBanner4') {
          eventData.value.webBannerUrl4 = data;
        } else if (type === 'webBanner5') {
          eventData.value.webBannerUrl5 = data;
        } else if (type === 'mobileBanner1') {
          eventData.value.mobileBannerUrl1 = data;
        } else if (type === 'mobileBanner2') {
          eventData.value.mobileBannerUrl2 = data;
        } else if (type === 'mobileBanner3') {
          eventData.value.mobileBannerUrl3 = data;
        } else if (type === 'mobileBanner4') {
          eventData.value.mobileBannerUrl4 = data;
        } else if (type === 'mobileBanner5') {
          eventData.value.mobileBannerUrl5 = data;
        }

      } catch (e) {
        alert(getServerErrorMessage(e))
      }
    }

    const handleChangeDate = (field, value) => {
      try {
        const isoDate = new Date(value).toISOString()
        handleChangeEventData(field, isoDate)
      } catch (error) {
        console.error(error)
      }
    }

    const getDateTimeLocalValue = (date) => {
      return `${ format(new Date(date), datePattern.INPUT_DATE) }T${ format(
        new Date(date),
        datePattern.INPUT_TIME_24
      ) }`
    }

    const getNowDateString = () => {
      const today = new Date()
      return `${ format(today, datePattern.INPUT_DATE) }T${ format(today, datePattern.INPUT_TIME_24) }`
    }


    const handleDeleteEvent = async () => {
      if (confirm('이벤트를 삭제하시겠습니까?\n' +
        '(이벤트에 포함된 작품은 삭제되지 않습니다.)')) {
        try {
          const { data } = await partnerAPI.adminEvent.adminEventDelete({
            eventIdArray: [Number(query.eventId)],
          })
          console.log(data)
          alert(data.message)
          await router.push('/app/event/list')
        } catch (e) {
          alert(getServerErrorMessage(e))
        }
      }
    }


    const handleRegister = async () => {
      if (eventData.value.code === '') {
        alert('이벤트 코드를 입력해주세요. 이벤트 코드는 영어소문자, 숫자 조합으로 20자 이내여야 합니다.')
        return
      }
      if (eventData.value.title === '') {
        alert('이벤트 타이틀을 입력해주세요.')
        return
      }
      if (eventData.value.startDate === '') {
        alert('이벤트 시작 기간을 설정해주세요.')
        return
      }
      if (eventData.value.endData === '') {
        alert('이벤트 종료 기간을 설정해주세요.')
        return
      }

      if (eventData.value.categoryV2 == null) {
        eventData.value.categoryV2 = []
      }

      if (eventData.value.categoryV2.length != eventData.value.category.length) {
        for (let i = 0; i < eventData.value.category.length; i++) {
          eventData.value.categoryV2.push({})
          if (eventData.value.categoryV2.length == eventData.value.category.length) {
            break;
          }
        }
      }

      for (let i = 0; i < eventData.value.category.length; i++ ) {
        const a = eventData.value.category[i]
        eventData.value.category[i] = a ? a : ''
        eventData.value.categoryV2[i].caption = eventData.value.category[i];
      }

      console.log(eventData.value.category)
      if (query.eventId) {
        //수정
        try {
          const { data } = await partnerAPI.adminEvent.adminEventUpdate({
            param: eventData.value
          })
          alert(data.message)
          await initEventData()
        } catch (e) {
          console.log('fail to update event : ' + e);
          alert(getServerErrorMessage(e))
        }
      } else {
        //생성
        try {
          const { data } = await partnerAPI.adminEvent.adminEventCreate({
            param: { ...eventData.value }
          })
          alert(data.message)
          router.push(`/app/event/list`)
        } catch (e) {
          alert(getServerErrorMessage(e))
        }
      }
    }

    const handleChangeEventData = (field, value) => {
      eventData.value = {
        ...eventData.value,
        [field]: value,
      }
      console.group('handleChangeEventData')
      console.log('field, value', field, value)
      console.groupEnd()
    }

    const handleChangeEventDataCategory = (index, value) => {
      eventData.value.category[index] = value
    }

    const initEventData = async () => {
      console.log(query.eventId)
      if (query.eventId) {
        try {
          const { data } = await partnerAPI.adminEvent.adminEventSingle({
            eventId: Number(query.eventId),
          })

          console.log(data)
          eventData.value = data.data
        } catch (error) {
          console.error(error)
        }
      }
    }


    const products = () => {
      const productData = ref({
        productList: [],
        event: {},
        total: 0
      })

      const filterParams = reactive({
        page: Number(query.page) || 0,
        pageSize: Number(query.pageSize) || 10,
      })

      const categoryBox = ref('')

      const handleAllCheck = (e) => {
        productData.value.productList = productData.value.productList.map(data => {
          return {
            ...data,
            checked: e.target.checked
          }
        })
      }

      const handleExceptEvent = async () => {
        const checkedData = productData.value.productList.filter(d => d.checked === true) || []
        if (checkedData.length === 0) {
          alert('선택된 행이 없습니다.')
          return
        }
        if (confirm('선택항목을 이벤트에서 제외합니다.\n' +
            '(작품이 삭제되는 것은 아닙니다.)')) {
          try {

            await partnerAPI.adminEvent.adminEventProductExcept({
              productIdArray: checkedData.map(d => d.productId)
            })

            alert('상품이 제외 되었습니다.')
            await fetchProductList()

          } catch (e) {
            console.error(e)
          }
        }

      }

      const handleChangeCategory = async () => {
        if (!categoryBox.value) {
          alert('선택된 카테고리가 없습니다.')
          return
        }
        const checkedData = productData.value.productList.filter(d => d.checked === true) || []
        if (checkedData.length === 0) {
          alert('선택된 행이 없습니다.')
          return
        }
        if (confirm(`선택황목의 카테고리를 ${categoryBox.value}(으)로 변경합니다.`)) {
          try {
            await partnerAPI.adminEvent.adminEventProductUpdate({
              eventCategory: categoryBox.value,
              productId: checkedData.map(d => d.productId)
            })

            alert('카테고리가 변경되었습니다.')
            await fetchProductList()

          } catch (e) {
            alert(getServerErrorMessage(e))
          }
        }
      }

      const handleCheck = (e, idx) => {
        console.log('cc', e.target.checked, idx)
        productData.value.productList[idx].checked = e.target.checked
      }


      const handleClickRow = (item) => {
        router.push({
          path: `/app/sales/detail/${item.productId}`
        })
      }

      const handleChangePage = (page) => {
        filterParams.page = page - 1
        executeSearch()
      }
      const handleChangePageSize = (pageSize) => {
        filterParams.page = 0
        filterParams.pageSize = pageSize
        executeSearch()
      }
      const handleChangeCategoryOption = (category) => {
        filterParams.page = 0
        filterParams.eventCategory = category
        executeSearch()
      }
      const handleChangePartnerOption = (partnerId) => {
        filterParams.page = 0
        filterParams.partnerId = partnerId
        executeSearch()
      }

      /**
       * 검색 실행
       */
      const executeSearch = () => {
        router.push({
          path: window.location.pathname,
          query: {
            page: filterParams.page || 0,
            pageSize: filterParams.pageSize || 10,
            eventCategory: filterParams.eventCategory,
            partnerId: filterParams.partnerId,
            eventId: eventData.value.eventId
          },
        })
      }

      const getAppliedFilterParams = () => {
        return {
          eventCode: eventData.value.code,
          eventCategory: filterParams.eventCategory,
          partnerId: filterParams.partnerId,
          page: filterParams.page,
          pageSize: filterParams.pageSize,
        }
      }

      const fetchProductList = async () => {
        console.log('fetchProductList')
        try {
          const { data: { data, total } } = await partnerAPI.adminEvent.adminEventProductList(
            getAppliedFilterParams()
          )
          productData.value = data
          productData.value.total = total
        } catch (e) {
          console.error(e)
          alert(e.response.data.message)
        }
      }

     // onMounted(fetchProductList)
      onMounted(async () => {
        await initEventData()
        await fetchProductList()
      })


      watch([ filterParams ], (newFilter, newIsFilterOn) => {
        console.log('watch filter', newFilter, newIsFilterOn)
        fetchProductList()
      })

      return {
        productData,
        filterParams,
        categoryBox,
        handleCheck,
        handleExceptEvent,
        handleChangeCategory,
        handleAllCheck,
        handleChangePage,
        handleChangePageSize,
        handleChangeCategoryOption,
        handleChangePartnerOption,
        handleClickRow
      }
    }


    return {
      convertNumWithComma,
      format,
      query,
      eventData,
      handleDeleteEvent,
      handleRegister,
      handleChangeImageInput,
      handleChangeEventDataCategory,
      handleChangeDate,
      handleChangeEventData,
      getDateTimeLocalValue,
      getNowDateString,
      ...products()
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/style/label";

.event-form {
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    background-size: 12px;
    background-position: 98% 50%;
    right: 0;
    top: 0;
    width: auto;
  }
}
.drag-container:hover {
  .hover-banner-text {
    display: flex;
  }
}

.hover-banner-text {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.banner-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100%;
}

.excel-download {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  color: #2563eb;
  background-color: #fff;
  border-color: #D9D9D9;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;

  &:checked {
    background-image: url('../../../assets/input_check-primary.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.table-bottom-line {
  width: 1px;
  height: 18px;
  background: #C4C4C4;;
}

.event-register-bottom {
  .VuePagination__count {
    display: none;
  }
}
.text-label-label {
  &.active {
    background: #000000;
    color: #fff;
  }
}
.admin-select {
  height: 2.25rem;
}
</style>
